.recommendedStudyHoursEditor {
    display: flex;
    column-gap: 1rem;

    .recommendedStudyHoursEditorText {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
