$smaller-bold-weight: 600;

.overallTitle {
    font-weight: $smaller-bold-weight;
    text-align: center;
    font-size: 125%;
    padding-bottom: 2rem;
}

.body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .formulaSheetEntry {
        border: solid 1px black;
        padding: 1rem;

        &.shouldForcePageBreakBefore {
            break-before: page;
        }

        .title {
            font-weight: $smaller-bold-weight;
        }

        .description {
            font-style: italic;
        }
    }
}
