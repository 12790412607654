@import '../../styles/modalTitle';

.formulaSheetEditor {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .formulaSheetEditorTitle {
        .modal-title;
    }

    .formulaSheetEditorSaveButtonContainer {
        display: flex;
        justify-content: center;
    }
}

.formulaSheetEditorModal {
    //margin: 0 3rem;
    //width: calc(100% - 6rem) !important;
    //
    //:global {
    //    .ant-modal-content {
    //        width: 100%;
    //        margin: 0;
    //
    //        .ant-modal-body {
    //
    //        }
    //    }
    //}
}
