@import '../../../../../styles/variables';

.excelFileUploader {
    border: 1px solid black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .excelFileUploaderHeader {
        font-weight: @bold-weight;
    }
}
