@import '../../../../styles/modalTitle';

.addSourceMaterialType {
    .addSourceMaterialTypeTitle {
        .modal-title;
    }

    .addSourceMaterialTypeBody {
        display: flex;
        column-gap: 1rem;
    }
}
