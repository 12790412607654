@import '../../styles/modalTitle';

// upload previous exam analysis
.uploadPreviousExamAnalysis {
    .uploadPreviousExamAnalysisTitle {
        .modal-title;
    }
}

.uploadPreviousExamAnalysisModal {
    margin: 0 3rem;
    width: calc(100% - 6rem) !important;

    :global {
        .ant-modal-content {
            width: 100%;
            margin: 0;

            .ant-modal-body {
            }
        }
    }
}
