@import '../../../../styles/modalTitle';

.practiceProblemsEditor {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .practiceProblemsEditorTitle {
        .modal-title;
    }

    .practiceProblemList {
        border: solid black 1px;
        height: 10rem;
        overflow: auto;
    }

    .practiceProblemSubContainer {
        display: flex;
        justify-content: space-between;

        .warningText {
            color: red;
            text-align: center;
            font-weight: @bold-weight;
        }
    }
}
