.container {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .entry {
        display: flex;
        column-gap: 1rem;

        .entryTitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: fit-content;
        }

        .subProblemSwitchOuter {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .subProblemSwitchInner {
                display: flex;
                column-gap: 1rem;

                .subProblemSwitchTitle {
                    width: fit-content;
                }

                .subProblemSwitchContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        :global {
            .ant-select {
                //width: 100%;
            }
        }
    }

    .subProblemAdditionalOptionsContainer {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }
}
