.contentTierTitlesEditor {
    .contentTierTitlesEditorText {
        margin-bottom: 0.5rem;
    }

    .contentTierTitlesEditorInputsContainer {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        margin-bottom: 0.5rem;
    }
}
