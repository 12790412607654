.practiceProblemMetadataEditor {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .metadatumEditor {
        display: flex;
        width: 100%;
        column-gap: 1rem;
        .metadatumEditorTitleContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            white-space: nowrap;
        }

        :global {
            .ant-select {
                width: 100%;
            }
        }
    }
}
