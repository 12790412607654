@import '../../../../../styles/variables';

.reactElementForPracticeProblemIdError {
    color: red;
    font-weight: @bold-weight;
    text-align: center;
}

.reactElementForPracticeProblemId {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    hr {
        border: 1px solid #9a9a9a;
        border-radius: 2px;
        width: 100%;
    }

    .nodeMetadataTitlesContainer {
        display: flex;
        flex-direction: column;
    }
}
