@import '../../styles/variables';

.editableText {
    position: relative;

    &.notEditing {
        .editableClickableText {
            cursor: text;

            &:hover {
                background: yellow;
            }
        }
    }

    &.editing {
        textarea {
            background: yellow;
        }

        cursor: auto;
    }

    &.loading {
        opacity: 0.5;
    }

    .editableTextLoadingOverlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        cursor: wait;
    }
}
