@import '../../../styles/variables';

.learningObjectivesEditor {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .learningObjectivesEditorTitle {
        font-weight: @bold-weight;
    }

    :global {
        .ant-btn {
            margin-top: 1rem;
        }
    }
}

.learningObjectivesEditorButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
