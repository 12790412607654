@import '../../../../styles/modalTitle';

.addPracticeProblemCategory {
    .addPracticeProblemCategoryTitle {
        .modal-title;
    }

    .addPracticeProblemCategoryBody {
        display: flex;
        column-gap: 1rem;
    }
}
