@import '../../styles/modalTitle';

.editCourseMetadata {
    padding: 1rem;

    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    hr {
        border: 1px solid #9a9a9a;
        border-radius: 2px;
        width: 100%;
    }

    .editCourseMetadataTitle {
        .modal-title;
    }
}

.editCourseMetadataModal {
    margin: 0 3rem;
    width: calc(100% - 6rem) !important;

    :global {
        .ant-modal-content {
            width: 100%;
            margin: 0;

            .ant-modal-body {
            }
        }
    }
}
