.learningObjectiveEditor {
    display: flex;
    .learningObjectiveEditorTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    column-gap: 1rem;

    :global {
        .ant-select {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            overflow: hidden;
        }
    }
}
