@import '../../../styles/variables';

.outlineProgressTreePanelHeader {
    &.hasUnsavedChanges {
        background: @unsaved-changes-color;
    }

    .outlineProgressTreePanelTextContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 5fr 1fr 1fr;
        grid-column-gap: 1rem;

        .reorderNodeOuterContainer {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 2;
            grid-column-end: 3;

            display: flex;
            flex-direction: row-reverse;
        }

        .farRightContainer {
            grid-row-start: 1;
            grid-row-end: 3;
            grid-column-start: 3;
            grid-column-end: 3;

            display: flex;
            flex-direction: column;

            .copyableIdOuterContainer {
                display: flex;
                flex-direction: row-reverse;
            }

            .deleteButtonOuterContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;

                .deleteButtonInnerContainer {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }

        .outlineProgressTreePanelHeaderTitle {
            color: @c-black;
            font-weight: @bold-weight;
            width: 100%;
        }

        .outlineProgressTreePanelHeaderDescription {
            color: @c-default-font-secondary;
            width: 100%;
        }
    }
}
