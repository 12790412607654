@import '../../../styles/variables';

.fileUploader {
    :global {
        .ant-btn {
            height: inherit;
            span {
                text-wrap: wrap;
            }
        }
    }

    .dropZoneButton {
        flex: 1 1 0;
        margin: 1rem;

        button {
            width: 100%;
        }

        span {
            pointer-events: none;
        }

        &.dragging {
            color: @antd-outline-btn-hover;
            border-color: @antd-outline-btn-hover;
        }

        &.draggingError {
            color: @error-color;
            border-color: @error-color;
        }
    }
}
