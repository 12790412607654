@import '../../styles/variables.less';

.resetPasswordOuterContainer {
    width: 100vw;

    .resetPasswordContainer {
        text-align: center;
        margin: 5vh 15% 0 15%;
        max-width: 50rem;
        min-width: 20rem;

        :global {
            .row {
                justify-content: center;

                h3 {
                    font-weight: @bold-weight;
                    color: @primary-color;
                    font-size: 28px;
                }

                span {
                    color: @primary-color;
                }

                .ant-form {
                    margin: 2rem 15% 0 15%;
                    width: 100%;
                    max-width: 30rem;
                }
            }
        }
    }
}

.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    :global {
        .ant-btn {
            &.ant-btn-primary {
                span {
                    color: #fff;
                    font-weight: @bold-weight;
                }
            }
        }
    }
}
