@import '../../../styles/responsiveText';

.readComment {
    :global {
        .editor {
            div {
                padding: 0 !important; // no padding around Magic Text Box in Comment, targeting ContentEditable__root
            }
        }
        .ant-comment-content {
            .ant-comment-content-author {
                .ant-comment-content-author-name {
                    .responsive-text-extra-small;
                    color: @c-gray-500;
                    font-weight: @bold-weight;
                }

                .ant-comment-content-author-time {
                    .responsive-text-extra-small;
                    color: @c-gray-400;
                }
            }

            .ant-comment-content-detail {
                .responsive-text-small;
            }

            .ant-comment-actions {
                margin-top: 0;
                .anticon-like {
                    .responsive-text-extra-small;
                }
                .commentAction {
                    padding-left: 0.2rem;
                }
            }
        }
    }
}
