@import '../../styles/variables.less';
@import '../../styles/responsiveText.less';

.commentThread {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;

    .commentThreadContainer {
        .responsive-text-small;
        display: flex;
        max-width: 55rem;
        width: 100%;
        flex-direction: column;
        padding: 1rem;

        .contentCommentThreadNoComments {
            display: flex;
            justify-content: center;
            .responsive-text-small;
            padding-bottom: 1rem;
        }

        .commentThreadTitle {
            font-weight: @bold-weight;
            .responsive-text-small;
            padding-bottom: 0.5rem;
        }

        .commentThreadBackToTopLink {
            padding-top: 0.5rem;
        }

        :global {
            .ant-list-empty-text {
                visibility: hidden;
            }

            .ant-comment-avatar {
                padding-top: 0.25rem;

                .avatar {
                    color: white;
                    cursor: default;
                    border-radius: 50%;

                    &.avatar-student {
                        background: @c-fuzzy-wuzzy;
                    }

                    &.avatar-instructor {
                        background: @primary-color;
                    }
                }
            }
        }
    }
}
