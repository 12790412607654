@import '../../styles/variables';

.error404 {
    z-index: 2;
    padding: 6rem;
    @media (max-width: @small-screen-max-width) {
        padding: 3rem;
    }

    .header {
        font-weight: @bold-weight;
        color: @c-default-font-darkened;
        font-style: italic;
        margin-bottom: 2rem;

        display: flex;
        flex-direction: row;
    }

    .text {
        color: @c-default-font;
    }

    img {
        max-width: 100%;
        max-height: 300px;
        margin: 6rem 0;
        @media (max-width: @small-screen-max-width) {
            margin: 3rem 0;
        }

        border-radius: 1rem;
    }
}
