.multipleChoicePracticeProblemSolutionsEditor {
    .addNewMultipleChoiceOptionButtonContainer {
    }

    .multipleChoiceOptionsListTitle {
        padding: 1rem 0 0.5rem;
    }

    .multipleChoiceOptionsList {
        border: solid black 1px;
        height: 10rem;
        overflow: auto;
    }

    .selectCorrectOptionContainer {
        .selectCorrectOptionTitleContainer {
            padding-bottom: 0.5rem;
            display: flex;
            white-space: nowrap;
        }

        :global {
            .ant-select {
                width: 100%;
            }
        }
    }

    .explanationEditor {
        padding-top: 1rem;
        .explanationEditorTitle {
        }
    }

    .toggleShouldShuffleAnswersContainer {
        padding: 1rem 0;

        .toggleShouldShuffleAnswersTitle {
            padding-bottom: 0.5rem;
        }
    }
}
