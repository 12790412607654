@import '../../styles/responsiveText.less';

.contentCreatorApplicationInstructions {
    margin: 2rem auto 0;
    max-width: 700px;
    .responsive-text-extra-small;
    line-height: 1.5715;

    h1 {
        .responsive-text-medium-small;
        text-align: center;
    }

    h2 {
        .responsive-text-small;
        text-align: center;
    }

    h3 {
        .responsive-text-small;
        text-align: center;
    }

    li {
        margin-bottom: 0.5rem;
    }
}
