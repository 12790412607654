.featureFlagsEditor {
    .featureFlagsText {
        padding-bottom: 1rem;
    }

    .featureFlagSwitches {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding-bottom: 1rem;

        .featureFlagSwitch {
            display: flex;
            column-gap: 1rem;

            .featureFlagSwitchLabel {
                //color: black;
            }
        }
    }
}
