.leafNodeContentEditorModal {
    margin: 0 3rem;
    width: calc(100% - 6rem) !important;

    :global {
        .ant-modal-content {
            width: 100%;
            margin: 0;

            .ant-modal-body {
            }
        }
    }
}

.leafNodeContentEditorButton {
    padding: 0.5rem;
}
