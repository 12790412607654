.notecardMetadataEditor {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .metadatumEditor {
        .metadatumEditorTitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        display: flex;
        width: 100%;
        column-gap: 1rem;

        :global {
            .ant-select {
                width: 100%;
            }
        }
    }
}
