.notecardCategory {
    // TODO:
}

.notecardCategoryPanelHeader {
    display: flex;
    justify-content: space-between;

    .notecardCategoryPanelHeaderLeftSide {
        .notecardCategoryTitle {
            padding-right: 0.5rem;
        }
    }
}
