.multiPartProblemSubProblemsEditor {
    .subProblemListTitle {
        padding: 1rem 0 0.5rem;
    }

    .subProblemList {
        border: solid black 1px;
        height: 10rem;
        overflow: auto;
    }
}
