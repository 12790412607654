.sourceMaterialType {
    // TODO:
}

.sourceMaterialTypePanelHeader {
    display: flex;
    justify-content: space-between;

    .sourceMaterialTypePanelHeaderLeftSide {
        .sourceMaterialTypeTitle {
            padding-right: 0.5rem;
        }
    }
}
