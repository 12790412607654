.releaseInfoEditor {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .releaseInfoEditorText {
    }

    .releaseInfoEditorReleaseStatus {
        display: flex;
        column-gap: 1rem;

        .releaseInfoEditorReleaseStatusText {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .releaseInfoEditorExpectedReleaseDate {
        display: flex;
        column-gap: 1rem;

        .releaseInfoEditorExpectedReleaseDateText {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .releaseInfoEditorExtraDetail {
        .releaseInfoEditorExtraDetailText {
        }
    }

    .releaseInfoEditorSaveButtonContainer {
        display: flex;
        justify-content: center;
    }
}
