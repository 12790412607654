@import '../../styles/variables.less';
@import '../../styles/responsiveText.less';

.signUpContainer {
    margin: 8rem auto;

    .signUpInstructionsContainer {
        margin: 0 auto;
        max-width: 700px;
        .responsive-text-small;
        line-height: 1.5715;

        h1 {
            .responsive-text-medium;
            text-align: center;
        }

        h2 {
            .responsive-text-medium-small;
            text-align: center;
        }
    }

    .signUpFormContainer {
        margin-top: 5rem;
        display: flex;
        justify-content: center;

        :global {
            .ant-input {
                .responsive-text-small;
            }

            .ant-form-item-row {
                width: 30rem;
                overflow: hidden;
            }

            .ant-form-item-label,
            .ant-form-item-control {
                max-width: 100%;

                .ant-input-affix-wrapper {
                    .responsive-text-small;
                }
            }

            .ant-select-selector {
                height: fit-content;
                padding: 3px 11px;
            }

            .ant-select-selection-placeholder,
            .ant-select-selection-item {
                .responsive-text-small;
            }

            .ant-btn {
                margin: 0 auto;
                .responsive-text-small;
                height: fit-content;
            }
        }

        .signUpFormButtonContainer {
            :global {
                .ant-col {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .ant-btn {
                        .responsive-text-medium-small;
                        height: fit-content;
                    }
                }
            }
        }
    }
}
