@import '../../styles/variables';
@import '../../styles/modalTitle';

.contentStats {
    .contentStatsTitle {
        .modal-title;
    }

    .contentStatsItem {
        display: flex;

        .contentStatsItemTitle {
            font-weight: @bold-weight;
            padding-right: 0.5rem;
        }

        .contentStatsItemText {
        }
    }

    .contentStatsSubitemsList {
        padding-left: 2rem;
    }
}
