@import '../../../styles/offScreenForPrinting.module';

.formulaSheetDownloadButtonContainer {
    button {
        height: 100%;
        font-size: 120%;
    }
}

.muleContainer {
    @include off-screen-for-printing;
}
