.practiceProblemCategory {
    // TODO:
}

.practiceProblemCategoryPanelHeader {
    display: flex;
    justify-content: space-between;

    .practiceProblemCategoryPanelHeaderLeftSide {
        .practiceProblemCategoryTitle {
            padding-right: 0.5rem;
        }
    }
}
