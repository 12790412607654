@import '../../../../styles/modalTitle';

.sourceMaterialsEditor {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .sourceMaterialsEditorTitle {
        .modal-title;
    }

    .sourceMaterialList {
        border: solid black 1px;
        height: 10rem;
        overflow: auto;
    }
}
