.magicTextBoxEditor {
    .editableView {
        :global {
            .editor-shell {
                border: solid 1px #d9d9d9;
                border-radius: 10px !important;
            }
        }
    }

    .nonEditableView {
    }
}
