@import '../../../styles/variables';

.loginForm {
    .loginFormTitle {
        font-weight: @bold-weight;
        margin-bottom: 0.5rem;
        color: @heading-color;
        font-size: 125%;
    }

    .forgotPasswordAndLoginButtonContainer {
        .forgotPasswordContainer {
            font-style: italic;
            margin-right: 2rem;
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .btnContainer {
            &.isSingleColumn {
                margin-top: 1rem;
            }

            &.isDualColumn {
            }
        }
    }
}
