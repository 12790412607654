@import '../../../styles/variables';

.forgotPasswordForm {
    .forgotPasswordInstructions {
    }

    .forgotPasswordAndLoginButtonContainer {
        .forgotPasswordContainer {
            font-style: italic;
            margin-right: 2rem;
        }

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .btnContainer {
            &.isSingleColumn {
                margin-top: 1rem;
            }

            &.isDualColumn {
            }
        }
    }
}
