@import '../../styles/variables';

.copyableId {
    display: flex;
    column-gap: 0.5rem;

    .copyableIdTextContainer {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .copyableIdContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        color: @primary-color;
    }
}
