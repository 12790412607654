@import '../../../../styles/variables';

.draggableIcon {
    cursor: grab;
    padding: 0 0.25rem;

    &.hasUnsavedChanges {
        background: @unsaved-changes-color;
        font-style: italic;
    }
}

.activeDraggableIcon {
    .draggableIcon {
        background: yellow;
    }
}

.dndProviderWrapper {
    // for loading icon
    img {
        // height is 10, but 9.75 allows hiding the scroll bar
        height: 9.75rem;
    }
}
