@import '../../../../../styles/modalTitle';

.sourceMaterialEditor {
    margin: 0 1rem 2rem 1rem;

    .sourceMaterialEditorTitle {
        .modal-title;
    }

    .saveButtonContainer {
        display: flex;
        column-gap: 2rem;
        padding-bottom: 2rem;

        .tooManyTokens {
            color: @error-color;
            font-weight: @bold-weight;
        }
    }

    .sourceMaterialEditorSection {
        padding: 1rem 0;

        .sourceMaterialEditorLabel {
            font-weight: @bold-weight;
        }

        :global {
            .ant-select {
                width: 100%;
            }
        }
    }

    .sourceMaterialEditorText {
        margin-top: 2rem;
        min-height: 16rem;
        font-family: @f-text;
        font-size: 16px;
    }
    .markdownRenderer {
        table {
            border-collapse: collapse; /* Ensures that the border is neat */
            width: 100%; /* Adjusts the table to span the entire width of the container */
        }

        th,
        td {
            border: 1px solid black; /* Sets a black border with 1px thickness for each cell */
            text-align: left; /* Aligns text to the left; you can change this to 'center' or 'right' if needed */
            padding: 8px; /* Adds space around the content inside each cell */
        }

        th {
            background-color: #f2f2f2; /* Sets a light grey background for header cells, you can change this color */
            color: black; /* Sets the text color for header cells */
        }
    }
}
