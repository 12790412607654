@import '../../../../../styles/variables';
@import '../../../../../styles/modalTitle';

.copyIndividualContent {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    :global {
        .ant-select {
            width: 100%;
        }
    }

    .copyIndividualContentTitle {
        .modal-title;
    }
}
