.hintsEditorContainer {
    display: flex;
    width: 100%;
    column-gap: 1rem;

    .hintsTitleContainer {
        display: flex;
        flex-direction: column;
        //justify-content: center;
        row-gap: 0.5rem;

        .hintsTitleText {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .addHintButtonContainer {
            display: flex;
            justify-content: center;
        }
    }

    .hintsEditor {
        border: solid black 1px;
        height: 10rem;
        overflow: auto;
        width: 100%;

        .activeHint {
            .draggableHint {
                background: yellow;
            }
        }

        .editableTextContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0.75rem 0.25rem;
        }

        .draggableHint {
            cursor: grab;
            display: flex;
            justify-content: space-between;
            padding: 0 0.25rem;
        }
    }
}
