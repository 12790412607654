@import '../../../styles/variables';

.formulaSheetEntry {
    display: flex;
    justify-content: space-between;

    padding: 0.25rem;
    border-radius: 0.5rem;

    .leftSide {
        display: flex;
        column-gap: 1rem;

        .formulaSheetEntryTitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.editFormulaSheetEntryModal {
    margin: 0 3rem;
    width: calc(100% - 6rem) !important;

    :global {
        .ant-modal-content {
            width: 100%;
            margin: 0;

            .ant-modal-body {
            }
        }
    }
}
