@import '../../../../styles/modalTitle';

.addNotecardCategory {
    .addNotecardCategoryTitle {
        .modal-title;
    }

    .addNotecardCategoryBody {
        display: flex;
        column-gap: 1rem;
    }
}
