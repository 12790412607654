@import '../../../styles/modalTitle';
@import '../../../styles/variables';

.editFormulaSheetEntry {
    .editFormulaSheetEntryTitle {
        .modal-title;
    }

    .editFormulaSheetEntryBody {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
        row-gap: 1rem;

        .row {
            column-gap: 1rem;
            display: flex;
            justify-content: space-between;

            .rowTitle {
                font-weight: @bold-weight;
            }

            &.switchRow {
                justify-content: start;
            }
        }
    }

    .footer {
        padding-top: 1rem;
        display: flex;
        flex-direction: row-reverse;
        column-gap: 1rem;
    }
}
