.primaryDashboard {
    padding-top: 1rem;

    .primaryDashboardTopButtonsContainer {
        padding: 1rem;
        display: flex;
        justify-content: center;
        column-gap: 3rem;
        row-gap: 1rem;
        flex-wrap: wrap;
    }
}
