.moveIndividualContent {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    :global {
        .ant-select {
            width: 100%;
        }
    }
}
