@import '../../../../../styles/modalTitle';

.copyLeafNode {
    :global {
        .ant-select {
            width: 100%;
        }
    }

    .copyLeafNodeTitle {
        .modal-title;
    }
}
